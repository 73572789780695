import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Popover,
  Typography,
  Link,
  TextField as MuiTextField,
  CircularProgress,
  Autocomplete as MuiAutocomplete,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { MuiFormikInput, ButtonSubmit, ComponentLoading } from 'components';

import { DialogAddAircraftManufacturer, DialogAddAircraftModel, ConfirmDeleteAircraftDialog } from '../Dialog';

import {
  DialogAddEngineManufacturerSuggestions,
  DialogAddEngineModelSuggestions,
  DialogAddEngineMonitorManufacturerSuggestions,
  DialogAddEngineMonitorModelSuggestions,
} from 'pages/SignupService/components';
import * as Yup from 'yup';

import { useLazyQuery, useQuery, useMutation } from '@apollo/client';

import {
  getOptionLabelIdName,
  formatOptionIdName,
  isEmpty,
  getCylinderNumber,
  getContactAddress,
  getOptionLabelFlagCountry,
} from 'helpers';
import { IAircraftDetail, IServiceCenter } from 'types';
import { Autocomplete, CustomDatePicker } from 'components';
import { useAutocomplete } from 'hooks';
import {
  QUERY_AIRCRAFT_MANUFACTURERS,
  QUERY_ENGINE_MANUFACTURER,
  QUERY_ENGINE_MONITOR_MANUFACTURER,
  MUTATION_UPDATE_AIRCRAFT,
  MUTATION_SET_DEFAULT_AIRCRAFT,
  MUTATION_DELETE_AIRCRAFT,
  MUTATION_CHANGE_PERSONAL_INFO,
  QUERY_SAVVY_SERVICE_CENTER_SEARCH,
} from 'gql';

import { apolloClient } from 'services';

import { setAlert } from 'state';
import {
  AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR,
  AIRCRAFT_MANUFACTURER_EMPTY_ERROR,
  ADD_OPTION_CLICKED_NO_VALUE,
  ADD_OPTION_CLICKED_TYPED_VALUE,
  GRAPHQL_NETWORK_ERROR_MESSAGE,
  PATH_AIRCRAFT_LIST,
  PATH_CONTACT,
  PATH_INTERNAL_SERVICE_CENTER,
} from 'const';
import { aircraftIgnitions, countries } from 'api';

const ValidationAircraftInfoSchema = Yup.object().shape({
  registration: Yup.string().max(20, 'Too long: Maximum 20 characters').required('Required'),
  year: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  serial: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
  chtWarningTemperature: Yup.number().integer(),
  leftIgntion: Yup.string().max(50, 'Too long: Maximum 50 characters'),
  rightIgnition: Yup.string().max(50, 'Too long: Maximum 50 characters'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  phoneMobile: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: Maximum 20 characters').required('Required'),
});

interface IDropDownOption {
  id: string;
  name: string;
}

interface IEngineOption {
  id: string;
  name: string;
  enginemodelSet?: any[];
}

interface IEngineMonitorOption {
  id: string;
  name: string;
  enginemonitormodelSet: any[];
}

interface AircraftInformationProps {
  aircraftDetail?: IAircraftDetail;
  canEditAircraftMakeModelForPaidAircraft?: boolean;
  asDialog?: boolean;
  setOpenDialog?: (value: boolean) => void;
  contactInfo?: {
    airportId: string;
    phoneMobile: string;
    country: string;
  };
}

const AircraftInformation: React.FC<AircraftInformationProps> = (props) => {
  const {
    aircraftDetail = undefined,
    canEditAircraftMakeModelForPaidAircraft = false,
    asDialog = false,
    setOpenDialog,
    contactInfo,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [disableEditProfile, setDisableEditProfile] = useState(true);

  const ADD_AUTOCOMPLETE_TEXT = '-- Add your own --';

  const {
    value: manufacturer,
    setValue: setManufacturer,
    error: errorManufacturer,
    setError: setErrorManufacturer,
    onChange: onChangeManufacturer,
  } = useAutocomplete();
  const [fetchManufacturer, { data: dataManufacturer, loading: loadingManufacturer, error: errorFetchManufacturer }] =
    useLazyQuery(QUERY_AIRCRAFT_MANUFACTURERS);
  const [deleteAircraftMutation, { data: dataDeleteAircraft, loading: loadingDeleteAircraft, error: errorDeleteAircraft }] = useMutation(
    MUTATION_DELETE_AIRCRAFT,
    {
      errorPolicy: 'all',
    },
  );

  const {
    value: country,
    error: errorCountry,
    setError: setErrorCountry,
    onChange: onChangeCountry,
    setValue: setCountry,
  } = useAutocomplete();

  const {
    value: preferredServiceCenter,
    setValue: setPreferredServiceCenter,
    error: errorPreferredServiceCenter,
    setError: setErrorPreferredServiceCenter,
  } = useAutocomplete();
  const [fetchServiceCenters, { data: dataServiceCenters, loading: loadingServiceCenters }] =
    useLazyQuery(QUERY_SAVVY_SERVICE_CENTER_SEARCH);
  const [searchServiceCenter, setSearchServiceCenter] = useState('');
  const [searchLoadingSc, setSearchLoadingSc] = useState(false);

  useEffect(() => {
    if (!searchServiceCenter || searchServiceCenter?.length < 2) {
      setSearchLoadingSc(false);
      return;
    }

    setSearchLoadingSc(true);
    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchServiceCenters({
        variables: {
          search: searchServiceCenter,
          limit: 100,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchServiceCenter, fetchServiceCenters]);

  useEffect(() => {
    if (!loadingServiceCenters && dataServiceCenters?.savvy?.search) {
      setSearchLoadingSc(false);
    }
  }, [dataServiceCenters, loadingServiceCenters]);

  useEffect(() => {
    if (errorFetchManufacturer) {
      dispatch(setAlert('error', GRAPHQL_NETWORK_ERROR_MESSAGE, 'ALERT_SERVER_ERROR_ID'));
    }
  }, [errorFetchManufacturer, dispatch]);

  const [manufacturerOptions, setManufacturerOptions] = useState<IDropDownOption[]>([]);

  const { value: model, setValue: setModel, error: errorModel, setError: setErrorModel, onChange: onChangeModel } = useAutocomplete();

  const [openAddManufacturer, setOpenAddManufacturer] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);

  const [modelOptions, setModelOptions] = useState<IDropDownOption[]>([]);

  const {
    value: engineManufacturer,
    setValue: setEngineManufacturer,
    error: errorEngineManufacturer,
    setError: setErrorEngineManufacturer,
    onChange: onChangeEngineManufacturer,
  } = useAutocomplete();
  const {
    value: engineModel,
    setValue: setEngineModel,
    error: errorEngineModel,
    setError: setErrorEngineModel,
    onChange: onChangeEngineModel,
  } = useAutocomplete();

  const {
    value: engineMonitorManufacturer,
    setValue: setEngineMonitorManufacturer,
    error: errorEngineMonitorManufacturer,
    setError: setErrorEngineMonitorManufacturer,
    onChange: onChangeEngineMonitorManufacturer,
  } = useAutocomplete();

  const {
    value: engineMonitorModel,
    setValue: setEngineMonitorModel,
    error: errorEngineMonitorModel,
    setError: setErrorEngineMonitorModel,
    onChange: onChangeEngineMonitorModel,
  } = useAutocomplete();

  const [engineManufacturerOptions, setEngineManufacturerOptions] = useState<IEngineOption[]>([]);
  const [engineModelOptions, setEngineModelOptions] = useState<IDropDownOption[]>([]);

  const { data: dataEngineManufacturer, loading: loadingEngineManufacturer } = useQuery(QUERY_ENGINE_MANUFACTURER);
  const { data: dataEngineMonitorManufacturer, loading: loadingEngineMonitorManufacturer } = useQuery(QUERY_ENGINE_MONITOR_MANUFACTURER);
  const [engineModelDisabled, setEngineModelDisabled] = useState(true);

  const [openAddEngineManufacturer, setOpenAddEngineManufacturer] = useState(false);
  const [openAddEngineModel, setOpenAddEngineModel] = useState(false);

  const [annualDue, setAnnualDue] = useState<Dayjs | null>(aircraftDetail?.annualDue ? dayjs(aircraftDetail.annualDue) : null);

  const [engineMonitorManufacturerOptions, setEngineMonitorManufacturerOptions] = useState<IEngineMonitorOption[]>([]);
  const [engineMonitorModelOptions, setEngineMonitorModelOptions] = useState<IDropDownOption[]>([]);
  const [installedEngineMonitor, setInstalledEngineMonitor] = useState(aircraftDetail?.aircraftHasNoMonitor);
  const [engineMonitorModelDisabled, setEngineMonitorModelDisabled] = useState(true);
  const [openAddEngineMonitorManufacturer, setOpenAddEngineMonitorManufacturer] = useState(false);
  const [openAddEngineMonitorModel, setOpenAddEngineMonitorModel] = useState(false);

  const [updateAircraft, { data: dataUpdateAircraft }] = useMutation(MUTATION_UPDATE_AIRCRAFT);

  const [setDefaultAircraft, { data: dataSetDefaultAircraft, error: errorSetDefaultAircraft, loading: loadingSetDefaultAircraft }] =
    useMutation(MUTATION_SET_DEFAULT_AIRCRAFT);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  const makeDefaultAircraft = async (aircraftId: string) => {
    await setDefaultAircraft({
      variables: {
        aircraftId,
      },
    });
  };

  useEffect(() => {
    if (errorSetDefaultAircraft) {
      dispatch(setAlert('error', 'Unable to make default aircraft'));
    } else {
      if (dataSetDefaultAircraft) {
        if (dataSetDefaultAircraft.setDefaultAircraft?.ok) {
          dispatch(setAlert('success', 'Set default property to aircraft'));
        } else {
          dispatch(setAlert('error', dataSetDefaultAircraft.setDefaultAircraft?.error || 'Unable to make default aircraft'));
        }
      }
    }
  }, [dataSetDefaultAircraft, errorSetDefaultAircraft, dispatch]);

  useEffect(() => {
    if (dataEngineMonitorManufacturer?.engineMonitorManufacturer !== undefined) {
      const sortEngineMonitorManufacturer = [...dataEngineMonitorManufacturer.engineMonitorManufacturer];
      sortEngineMonitorManufacturer.sort((x: IEngineMonitorOption, y: IEngineMonitorOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineMonitorManufacturerOptions([
        ...sortEngineMonitorManufacturer,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }
  }, [dataEngineMonitorManufacturer]);

  useEffect(() => {
    if (dataManufacturer) {
      const aircraftManufacturer = [...dataManufacturer.aircraftManufacturer];
      aircraftManufacturer.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      const tmp = [...aircraftManufacturer]; // Update options
      tmp.push({
        id: '',
        name: '-- Add your own --',
      });

      setManufacturerOptions([...tmp]);
    }
  }, [dataManufacturer, setManufacturerOptions]);

  useEffect(() => {
    if (dataEngineManufacturer?.engineManufacturer !== undefined) {
      const sort = [...dataEngineManufacturer.engineManufacturer];

      sort?.sort((x: IEngineOption, y: IEngineOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });

      setEngineManufacturerOptions([
        ...sort,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }
  }, [dataEngineManufacturer]);

  useEffect(() => {
    let tmp: IDropDownOption[] = [];
    if (manufacturer?.id && manufacturer?.aircraftmodelSet) {
      tmp = [...manufacturer.aircraftmodelSet];
      tmp.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
    }

    tmp.push({
      id: '',
      name: '-- Add your own --',
    });

    setModelOptions(tmp);
  }, [manufacturer]);

  useEffect(() => {
    setManufacturer(aircraftDetail?.aircraftManufacturer);
    setModel(aircraftDetail?.aircraftModel);
    setEngineManufacturer(aircraftDetail?.engineManufacturer);
    setEngineModel(aircraftDetail?.engineModel);
    setEngineMonitorManufacturer(aircraftDetail?.engineMonitorManufacturer);
    setEngineMonitorModel(aircraftDetail?.engineMonitorModel);
    setInstalledEngineMonitor(aircraftDetail?.aircraftHasNoMonitor);
    setPreferredServiceCenter(aircraftDetail?.preferredServiceCenter);
  }, [
    aircraftDetail,
    setManufacturer,
    setModel,
    setEngineManufacturer,
    setEngineModel,
    setEngineMonitorManufacturer,
    setEngineMonitorModel,
    setInstalledEngineMonitor,
  ]);

  const requireManufacturer = () => {
    if (manufacturer !== null && isEmpty(manufacturer.name)) {
      setErrorManufacturer(AIRCRAFT_MANUFACTURER_EMPTY_ERROR);
      setErrorModel(AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR);
    }
  };

  const setInitial = () => {
    setManufacturer(aircraftDetail?.aircraftManufacturer);
    setModel(aircraftDetail?.aircraftModel);
    setEngineManufacturer(aircraftDetail?.engineManufacturer);
    setEngineModel(aircraftDetail?.engineModel);
    setEngineMonitorManufacturer(aircraftDetail?.engineMonitorManufacturer);
    setEngineMonitorModel(aircraftDetail?.engineMonitorModel);
    setInstalledEngineMonitor(aircraftDetail?.aircraftHasNoMonitor);
    setDisableEditProfile(true);
  };

  // Open if "Add option" clicked in Autocomplete
  useEffect(() => {
    // setResponseError([]);
    if (manufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || manufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setManufacturer({
        id: '',
        name: manufacturer?.name && manufacturer.name !== '-- Add your own --' ? manufacturer.name.replace('Add ', '') : '',
      });
      setOpenAddManufacturer(true);
      setOpenAddModel(false);
    } else if (model?.type === ADD_OPTION_CLICKED_NO_VALUE || model?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setModel({
        id: '',
        name: model?.name && model.name !== '-- Add your own --' ? model.name.replace('Add ', '') : '',
      });
      setOpenAddModel(true); // Open dialog
      setOpenAddManufacturer(false);
    }
  }, [manufacturer, model, setManufacturer, setModel]);

  const [modelDisabled, setModelDisabled] = useState(true);
  useEffect(() => {
    // If Manufacturer changed
    if (manufacturer && manufacturer?.id !== aircraftDetail?.aircraftManufacturer?.id) setModel(null);

    // If Manufacturer Empty
    if (manufacturer === null || (!manufacturer?.id && !manufacturer?.name)) {
      setModelDisabled(true); // Disable Model
    } else {
      // If Manufacturer NOT Empty
      setErrorModel(null); // Clean Model Error
      setModelDisabled(false); // Enable Model
    }
  }, [manufacturer, setModel, setErrorModel, aircraftDetail]);

  useEffect(() => {
    if (engineManufacturer && engineManufacturer?.id !== aircraftDetail?.engineManufacturer?.id) setEngineModel(null);

    if (engineManufacturer?.id && engineManufacturer?.enginemodelSet) {
      const engineModelSet = [...engineManufacturer.enginemodelSet];
      engineModelSet.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineModelOptions([
        ...engineModelSet,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    } else {
      setEngineModelOptions([
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }

    if (engineManufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || engineManufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineManufacturer({
        id: '',
        name:
          engineManufacturer?.name && engineManufacturer.name !== '-- Add your own --' ? engineManufacturer.name.replace('Add ', '') : '',
      });
      setOpenAddEngineManufacturer(true);
      setOpenAddEngineModel(false);
    }

    if (engineManufacturer === null || (!engineManufacturer?.id && !engineManufacturer?.name)) {
      setEngineModelDisabled(true);
    } else {
      setEngineModelDisabled(false);
    }
  }, [engineManufacturer, setEngineManufacturer, setEngineModel, aircraftDetail]);

  useEffect(() => {
    if (engineModel?.type === ADD_OPTION_CLICKED_NO_VALUE || engineModel?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineModel({
        id: '',
        name: engineModel?.name && engineModel.name !== '-- Add your own --' ? engineModel.name.replace('Add ', '') : '',
      });

      setOpenAddEngineModel(true); // Open dialog
      setOpenAddEngineManufacturer(false);
    }
  }, [engineModel, setEngineModel]);

  useEffect(() => {
    if (engineMonitorManufacturer && engineMonitorManufacturer?.id !== aircraftDetail?.engineMonitorManufacturer?.id)
      setEngineMonitorModel(null);

    if (engineMonitorManufacturer?.id && engineMonitorManufacturer.enginemonitormodelSet) {
      const engineMonitorModelSet = [...engineMonitorManufacturer.enginemonitormodelSet];
      engineMonitorModelSet.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineMonitorModelOptions([
        ...engineMonitorModelSet,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    } else {
      setEngineMonitorModelOptions([
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }

    // setResponseError([]);
    if (
      engineMonitorManufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE ||
      engineMonitorManufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE
    ) {
      setEngineMonitorManufacturer({
        id: '',
        name:
          engineMonitorManufacturer?.name && engineMonitorManufacturer.name !== '-- Add your own --'
            ? engineMonitorManufacturer.name.replace('Add ', '')
            : '',
      });
      setOpenAddEngineMonitorManufacturer(true);
      setOpenAddEngineMonitorModel(false);
    }

    if (engineMonitorManufacturer === null || (!engineMonitorManufacturer?.id && !engineMonitorManufacturer?.name)) {
      setEngineMonitorModelDisabled(true);
    } else {
      setEngineMonitorModelDisabled(false);
    }
  }, [engineMonitorManufacturer, setEngineMonitorModelOptions, setEngineMonitorManufacturer, setEngineMonitorModel, aircraftDetail]);

  useEffect(() => {
    if (engineMonitorModel?.type === ADD_OPTION_CLICKED_NO_VALUE || engineMonitorModel?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineMonitorModel({
        id: '',
        name:
          engineMonitorModel?.name && engineMonitorModel.name !== '-- Add your own --' ? engineMonitorModel.name.replace('Add ', '') : '',
      });

      setOpenAddEngineMonitorModel(true); // Open dialog
      setOpenAddEngineMonitorManufacturer(false);
    }
  }, [engineMonitorModel, setEngineMonitorModel]);

  const onSubmit = async (values: any) => {
    let hasError = false;

    if (!country && contactInfo) {
      setErrorCountry('Required');
      hasError = true;
    }

    if (!engineManufacturer?.id) {
      setErrorEngineManufacturer('Required');
      hasError = true;
    }

    if (!engineModel?.id) {
      setErrorEngineModel('Required');
      hasError = true;
    }

    if (!installedEngineMonitor) {
      if (!engineMonitorManufacturer?.id) {
        setErrorEngineMonitorManufacturer('Required');
        hasError = true;
      }

      if (!engineMonitorModel?.id) {
        setErrorEngineMonitorModel('Required');
        hasError = true;
      }
    }

    if (aircraftDetail?.canUpdateAircraftMakeModel || canEditAircraftMakeModelForPaidAircraft) {
      if (!manufacturer?.id) {
        setErrorManufacturer('Required');
        hasError = true;
      }

      if (!model?.id) {
        setErrorModel('Required');
        hasError = true;
      }
    }

    if (hasError) return;

    const aircraftId = parseInt(aircraftDetail?.id || '0');

    if (contactInfo) {
      const { errors, data } = await apolloClient.mutate({
        mutation: MUTATION_CHANGE_PERSONAL_INFO,
        variables: {
          airportId: values.airportId,
          countryCode: country.id,
          mobilePhone: values.phoneMobile,
        },
      });

      if (errors) {
        dispatch(setAlert('error', data?.changePersonalInfo?.error || 'Unable to update personal info'));
        return;
      }
    }

    if (!aircraftDetail?.canUpdateAircraftMakeModel && !canEditAircraftMakeModelForPaidAircraft && aircraftDetail?.id !== undefined) {
      await updateAircraft({
        variables: {
          aircraftId: aircraftId,
          aircraftHasNoMonitor: installedEngineMonitor,
          engineManufacturerId: parseInt(engineManufacturer.id),
          engineModelId: parseInt(engineModel.id),
          engineMonitorManufacturerId: parseInt(engineMonitorManufacturer?.id),
          engineMonitorModelId: parseInt(engineMonitorModel?.id),
          serial: values.serial,
          year: values.year ? parseInt(values.year) : null,
          registration: values.registration,
          cylinderCount: values.cylinderCount ? parseInt(values.cylinderCount) : null,
          annualDue: annualDue ? annualDue.format('YYYY-MM-DD') : null,
          leftIgnition: values.leftIgnition ? values.leftIgnition : null,
          rightIgnition: values.rightIgnition ? values.rightIgnition : null,
          chtWarningTemperature: values.chtWarningTemperature ? parseInt(values.chtWarningTemperature) : null,
          preferredServiceCenterId: preferredServiceCenter ? preferredServiceCenter.id : null,
        },
      });
    } else if (aircraftDetail?.id) {
      await updateAircraft({
        variables: {
          aircraftId: aircraftId,
          aircraftManufacturerId: parseInt(manufacturer.id),
          aircraftModelId: parseInt(model.id),
          aircraftHasNoMonitor: installedEngineMonitor,
          engineManufacturerId: parseInt(engineManufacturer.id),
          engineModelId: parseInt(engineModel.id),
          engineMonitorManufacturerId: parseInt(engineMonitorManufacturer?.id),
          engineMonitorModelId: parseInt(engineMonitorModel?.id),
          serial: values.serial,
          year: values.year ? parseInt(values.year) : null,
          registration: values.registration,
          cylinderCount: values.cylinderCount ? parseInt(values.cylinderCount) : null,
          annualDue: annualDue ? annualDue.format('YYYY-MM-DD') : null,
          leftIgnition: values.leftIgnition ? values.leftIgnition : null,
          rightIgnition: values.rightIgnition ? values.rightIgnition : null,
          chtWarningTemperature: values.chtWarningTemperature ? parseInt(values.chtWarningTemperature) : null,
          preferredServiceCenterId: preferredServiceCenter ? preferredServiceCenter.id : null,
        },
      });
    }
  };

  const deleteAircraft = async () => {
    await deleteAircraftMutation({
      variables: {
        aircraftId: aircraftDetail?.id,
      },
    });
  };

  useEffect(() => {
    if (dataUpdateAircraft) {
      const { errors: aircraftErrors } = dataUpdateAircraft.updateAircraft;

      if (aircraftErrors) {
        dispatch(setAlert('error', 'Unable to update aircraft information. If this error persists, please contact support.'));
      } else {
        if (dataUpdateAircraft.updateAircraft.ok) {
          dispatch(setAlert('success', 'Updated aircraft information.'));
          if (asDialog && setOpenDialog) {
            setOpenDialog(false);
          } else {
            setDisableEditProfile(true);
          }
        } else {
          dispatch(
            setAlert(
              'error',
              dataUpdateAircraft.updateAircraft.error ||
                'Unable to update aircraft information. If this error persists, please contact support.',
            ),
          );
        }
      }
    }
  }, [dataUpdateAircraft, dispatch, asDialog, setOpenDialog]);

  useEffect(() => {
    if (errorDeleteAircraft) {
      dispatch(
        setAlert(
          'error',
          errorDeleteAircraft?.graphQLErrors[0]?.message ? errorDeleteAircraft?.graphQLErrors[0]?.message : 'Unable to delete aircraft',
        ),
      );
    } else if (dataDeleteAircraft) {
      if (dataDeleteAircraft?.deleteAircraft?.ok) {
        dispatch(setAlert('success', 'Deleted aircraft'));
        if (!isSavvy) {
          history.push(PATH_AIRCRAFT_LIST);
        } else {
          history.push(`${PATH_CONTACT}/${aircraftDetail?.owner?.id}/aircraft`);
        }
      } else {
        dispatch(setAlert('error', dataDeleteAircraft?.deleteAircraft?.error || 'Unable to delete aircraft'));
      }
    }
  }, [errorDeleteAircraft, dataDeleteAircraft, dispatch, history, isSavvy, aircraftDetail]);

  useEffect(() => {
    fetchManufacturer();
  }, [fetchManufacturer]);

  useEffect(() => {
    if (asDialog) {
      setDisableEditProfile(false);
    }
  }, [asDialog]);

  useEffect(() => {
    if (contactInfo?.country) {
      const filteredCountry = countries.filter((country) => country.id === contactInfo.country);
      if (filteredCountry?.length) {
        setCountry(filteredCountry[0]);
      }
    }
  }, [contactInfo, setCountry]);

  if (!aircraftDetail) {
    return null;
  }

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading loading={loadingSetDefaultAircraft || loadingDeleteAircraft}>
        <Formik
          initialValues={{
            registration: aircraftDetail?.registration ? aircraftDetail.registration : '',
            year: aircraftDetail?.year ? aircraftDetail.year : '',
            serial: aircraftDetail?.serial ? aircraftDetail.serial : '',
            chtWarningTemperature: aircraftDetail?.chtWarningTemperature ? aircraftDetail.chtWarningTemperature : '',
            installedEngineMonitor: aircraftDetail?.aircraftHasNoMonitor !== undefined ? aircraftDetail?.aircraftHasNoMonitor : false,
            cylinderCount: aircraftDetail?.cylinderCount ? getCylinderNumber(aircraftDetail.cylinderCount) : 0,
            leftIgnition: aircraftDetail?.leftIgnition ? aircraftDetail.leftIgnition : '',
            rightIgnition: aircraftDetail?.rightIgnition ? aircraftDetail.rightIgnition : '',
            airportId: contactInfo ? contactInfo.airportId : '1234',
            phoneMobile: contactInfo ? contactInfo.phoneMobile : '123-123-1234',
          }}
          onSubmit={onSubmit}
          validationSchema={ValidationAircraftInfoSchema}
          enableReinitialize
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors, resetForm }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  {!disableEditProfile && !aircraftDetail?.canUpdateAircraftMakeModel && !canEditAircraftMakeModelForPaidAircraft && (
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        sx={{
                          color: 'error.main',
                        }}
                        gutterBottom
                      >
                        This aircraft has an active plan, and as such it is not possible to edit the aircraft make/model. You can however
                        edit other fields.
                      </Typography>
                    </Grid>
                  )}
                  {contactInfo && (
                    <React.Fragment>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                        <MuiFormikInput
                          name="airportId"
                          label="Airport ID *"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={disableEditProfile}
                        />
                      </Grid>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                        <MuiFormikInput
                          name="phoneMobile"
                          label="Mobile Phone *"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={disableEditProfile}
                        />
                      </Grid>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                        <Autocomplete
                          label="Country *"
                          name="country"
                          value={country}
                          error={errorCountry}
                          onChange={(e) => onChangeCountry(e)}
                          options={countries}
                          getOptionLabel={getOptionLabelFlagCountry}
                          formatOption={formatOptionIdName}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <MuiFormikInput
                      name="registration"
                      label="Registration Number *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <MuiFormikInput
                      name="serial"
                      label="Serial *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <MuiFormikInput
                      name="year"
                      label="Model Year *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Aircraft manufacturer *"
                      name="aircraft_manufacturer"
                      value={manufacturer ? manufacturer : null}
                      error={errorManufacturer}
                      onChange={onChangeManufacturer}
                      options={manufacturerOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingManufacturer}
                      addOptionLabel="-- Add your own --"
                      renderOptionFlag={true}
                      placeholder="Start typing to search..."
                      disabled={
                        disableEditProfile ||
                        (aircraftDetail?.canUpdateAircraftMakeModel === false && !canEditAircraftMakeModelForPaidAircraft)
                      }
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Aircraft model *"
                      name="aircraft_model"
                      value={model ? model : null}
                      error={errorModel}
                      onChange={onChangeModel}
                      options={modelOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      addOptionLabel="-- Add your own --"
                      disabled={
                        modelDisabled ||
                        disableEditProfile ||
                        (aircraftDetail?.canUpdateAircraftMakeModel === false && !canEditAircraftMakeModelForPaidAircraft)
                      }
                      onClick={requireManufacturer}
                      renderOptionFlag={true}
                      placeholder="Start typing to search..."
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <MuiFormikInput
                      name="chtWarningTemperature"
                      label="CHT Warning Temperature"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="cylinderCount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Number of Cylinders"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                      disabled={disableEditProfile}
                    >
                      <MenuItem value={0}>Select number of cylinders</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="leftIgnition"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Left Ignition"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                      disabled={disableEditProfile}
                    >
                      {aircraftIgnitions.map((ignition: IDropDownOption, key: number) => (
                        <MenuItem value={ignition.id} key={key}>
                          {ignition.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      name="rightIgnition"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Right Ignition"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                      disabled={disableEditProfile}
                    >
                      {aircraftIgnitions.map((ignition: IDropDownOption, key: number) => (
                        <MenuItem value={ignition.id} key={key}>
                          {ignition.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Engine Manufacturer *"
                      name="engineManufacturer"
                      value={engineManufacturer ? engineManufacturer : null}
                      error={errorEngineManufacturer}
                      onChange={onChangeEngineManufacturer}
                      options={engineManufacturerOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingEngineManufacturer}
                      addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
                      placeholder="Start typing to search..."
                      renderOptionFlag={true}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Engine Model *"
                      name="engineModel"
                      value={engineModel ? engineModel : null}
                      error={errorEngineModel}
                      onChange={onChangeEngineModel}
                      options={engineModelOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingEngineManufacturer}
                      addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
                      disabled={engineModelDisabled || disableEditProfile}
                      placeholder="Start typing to search..."
                      renderOptionFlag={true}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Engine Monitor Manufacturer *"
                      name="engineMonitorManufacturer"
                      value={engineMonitorManufacturer ? engineMonitorManufacturer : null}
                      error={!installedEngineMonitor ? errorEngineMonitorManufacturer : ''}
                      onChange={onChangeEngineMonitorManufacturer}
                      options={engineMonitorManufacturerOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingEngineMonitorManufacturer}
                      addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
                      placeholder="Start typing to search..."
                      disabled={installedEngineMonitor || disableEditProfile}
                      renderOptionFlag={true}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      label="Engine Monitor Model *"
                      name="engineMonitorModel"
                      value={engineMonitorModel ? engineMonitorModel : null}
                      error={!installedEngineMonitor ? errorEngineMonitorModel : ''}
                      onChange={onChangeEngineMonitorModel}
                      options={engineMonitorModelOptions}
                      getOptionLabel={getOptionLabelIdName}
                      formatOption={formatOptionIdName}
                      loading={loadingEngineMonitorManufacturer}
                      addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
                      disabled={engineMonitorModelDisabled || installedEngineMonitor || disableEditProfile}
                      placeholder="Start typing to search..."
                      renderOptionFlag={true}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      type="checkbox"
                      name="installedEngineMonitor"
                      component={CheckboxWithLabel}
                      Label={{ label: 'Engine Monitor Not Installed' }}
                      onChange={(e: any) => {
                        setInstalledEngineMonitor(!installedEngineMonitor);
                        handleChange(e);
                      }}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                    <CustomDatePicker
                      label="Annual Due"
                      value={annualDue}
                      setValue={setAnnualDue}
                      sx={{
                        ml: 0,
                      }}
                      containerSx={{
                        mb: 0,
                      }}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  {isSavvy && !disableEditProfile && (
                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                      <MuiAutocomplete
                        options={
                          dataServiceCenters?.savvy?.search?.serviceCenters?.results
                            ? dataServiceCenters?.savvy?.search?.serviceCenters?.results
                            : preferredServiceCenter
                              ? [preferredServiceCenter]
                              : []
                        }
                        getOptionLabel={(option: IServiceCenter) =>
                          option?.id ? `${option.company} ${getContactAddress(option) ? '(' + getContactAddress(option) + ')' : ''}` : ''
                        }
                        value={preferredServiceCenter ? preferredServiceCenter : null}
                        onChange={(event: any, value: any) => {
                          if (value && value.id) {
                            setPreferredServiceCenter(value);
                            setErrorPreferredServiceCenter('');
                          }
                        }}
                        isOptionEqualToValue={(option: IServiceCenter, value: IServiceCenter) => option.id === value.id}
                        loading={searchLoadingSc}
                        renderInput={(params: any) => (
                          <MuiTextField
                            {...params}
                            label={'Preferred Service Center'}
                            placeholder="Type service center"
                            value={searchServiceCenter}
                            onChange={(e: any) => {
                              setSearchServiceCenter(e.target.value);
                              setErrorPreferredServiceCenter('');
                            }}
                            error={!!errorPreferredServiceCenter}
                            helperText={errorPreferredServiceCenter || ''}
                            FormHelperTextProps={{
                              sx: {
                                ml: 0,
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {searchLoadingSc ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            fullWidth
                          />
                        )}
                        renderOption={(props: any, option: IServiceCenter) => {
                          return option?.id ? (
                            <li {...props} key={option.id}>{`${option.company} ${
                              getContactAddress(option) ? '(' + getContactAddress(option) + ')' : ''
                            }`}</li>
                          ) : null;
                        }}
                        disableClearable={true}
                      />
                    </Grid>
                  )}
                  {isSavvy && disableEditProfile && preferredServiceCenter && (
                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                      <Box>
                        <Typography>
                          Preferred Service Center:&nbsp;
                          <Link href={PATH_INTERNAL_SERVICE_CENTER.replace(':id', preferredServiceCenter.id)} target="_blank">
                            {preferredServiceCenter.company}
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Box py={2}>
                  {disableEditProfile && (
                    <Box
                      sx={{
                        display: {
                          md: 'flex',
                          xs: 'block',
                        },
                        '& button': {
                          maxWidth: '350px',
                        },
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          setDisableEditProfile(false);
                        }}
                        sx={{
                          mb: {
                            md: 0,
                            xs: 1,
                          },
                        }}
                      >
                        Edit Aircraft
                      </Button>
                      <Box
                        sx={{
                          ml: {
                            md: 2,
                            xs: 0,
                          },
                          mb: {
                            md: 0,
                            xs: 1,
                          },
                          maxWidth: '350px',
                          width: '100%',
                        }}
                        onMouseEnter={(event) => {
                          if (aircraftDetail?.default) {
                            handlePopoverOpen(event);
                          } else {
                            return;
                          }
                        }}
                        onMouseLeave={() => {
                          if (aircraftDetail?.default) {
                            handlePopoverClose();
                          } else {
                            return;
                          }
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="warning"
                          fullWidth
                          onClick={() => {
                            makeDefaultAircraft(aircraftDetail.id);
                          }}
                          disabled={aircraftDetail?.default}
                        >
                          Make Default
                        </Button>
                        <Popover
                          open={popoverOpen}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          sx={{
                            pointerEvents: 'none',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          onClose={handlePopoverClose}
                        >
                          <Typography
                            sx={{
                              p: 1,
                            }}
                          >
                            Aircraft is already the default.
                          </Typography>
                        </Popover>
                      </Box>
                      <Button
                        variant="outlined"
                        color="error"
                        fullWidth
                        onClick={() => {
                          setDeleteConfirm(true);
                        }}
                        sx={{
                          ml: {
                            md: 2,
                            xs: 0,
                          },
                          mb: {
                            md: 0,
                            xs: 1,
                          },
                        }}
                      >
                        Delete Aircraft
                      </Button>
                    </Box>
                  )}
                  {!disableEditProfile && (
                    <Box
                      sx={{
                        display: 'flex',
                        '& button': {
                          maxWidth: '350px',
                        },
                        justifyContent: 'center',
                      }}
                    >
                      <ButtonSubmit text="Save" loading={isSubmitting} />
                      {!asDialog && (
                        <Button
                          variant="outlined"
                          fullWidth
                          color="warning"
                          onClick={() => {
                            resetForm();
                            setInitial();
                          }}
                          sx={{
                            ml: 2,
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
                <DialogAddAircraftManufacturer
                  open={openAddManufacturer}
                  setOpen={setOpenAddManufacturer}
                  manufacturer={manufacturer}
                  setManufacturer={setManufacturer}
                />

                <DialogAddAircraftModel
                  open={openAddModel}
                  setOpen={setOpenAddModel}
                  manufacturer={manufacturer}
                  setManufacturer={setManufacturer}
                  model={model}
                  setModel={setModel}
                />
                <DialogAddEngineManufacturerSuggestions
                  engineManufacturer={engineManufacturer}
                  setEngineManufacturer={setEngineManufacturer}
                  open={openAddEngineManufacturer}
                  setOpen={setOpenAddEngineManufacturer}
                />

                <DialogAddEngineModelSuggestions
                  engineManufacturer={engineManufacturer}
                  engineModel={engineModel}
                  setEngineModel={setEngineModel}
                  open={openAddEngineModel}
                  setOpen={setOpenAddEngineModel}
                />

                <DialogAddEngineMonitorManufacturerSuggestions
                  engineMonitorManufacturer={engineMonitorManufacturer}
                  setEngineMonitorManufacturer={setEngineMonitorManufacturer}
                  open={openAddEngineMonitorManufacturer}
                  setOpen={setOpenAddEngineMonitorManufacturer}
                />

                <DialogAddEngineMonitorModelSuggestions
                  engineMonitorManufacturer={engineMonitorManufacturer}
                  engineMonitorModel={engineMonitorModel}
                  setEngineMonitorModel={setEngineMonitorModel}
                  open={openAddEngineMonitorModel}
                  setOpen={setOpenAddEngineMonitorModel}
                />
              </Form>
            );
          }}
        </Formik>
        <ConfirmDeleteAircraftDialog open={deleteConfirm} setOpen={setDeleteConfirm} onSubmitAction={deleteAircraft} />
      </ComponentLoading>
    </Box>
  );
};

export default AircraftInformation;
