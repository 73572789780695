import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, TextField, InputAdornment, Collapse, IconButton, Hidden, Switch, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { PATH_PERSONAL_TICKET_DASHBOARD } from 'const';

import { ISavvyTicket } from 'types';
import { default as SubCollapsedTickets } from './SubCollapsedTickets';

import { QUERY_PERSONAL_TICKET_DASHBOARD, QUERY_PERSONAL_TICKET_DASHBOARD_EVERY_MINUTE } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { useTimer } from 'hooks';
import { setAlert } from 'state';

import { PageLoading } from 'components';

interface ITicketGroup {
  name: string;
  description: string;
  tickets: ISavvyTicket[];
}

interface CollapsedTicketsProps {
  clickTicket: (event: any, value: string) => void;
}

const CollapsedTickets: React.FC<CollapsedTicketsProps> = (props) => {
  const { clickTicket } = props;

  const [fetchTickets, { data: dataTickets, loading: loadingTickets, error: errorTickets }] = useLazyQuery(QUERY_PERSONAL_TICKET_DASHBOARD);
  const dispatch = useDispatch();

  const [timerTickets] = useLazyQuery(QUERY_PERSONAL_TICKET_DASHBOARD_EVERY_MINUTE, {
    fetchPolicy: 'cache-and-network',
  });

  const FETCH_DASHBOARD_INTERVAL = 60;

  const { id } = useParams<{
    id?: string;
  }>();

  const timerAction = async () => {
    await timerTickets({
      variables: {
        ticketId: id ? parseInt(id) : null,
      },
    });
    fetchTickets();
  };

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_DASHBOARD_INTERVAL,
    timerAction,
  });

  const [leftSlideOn, setLeftSlideOn] = useState(true);

  const [subjectSearch, setSubjectSearch] = useState('');

  const [checkUnread, setCheckUnread] = useState(false);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  useEffect(() => {
    if (!loadingTickets && dataTickets) {
      if (!createdTimer) {
        createTimer();
      }
    }
  }, [dataTickets, loadingTickets, createTimer, createdTimer]);

  useEffect(() => {
    if (errorTickets) {
      dispatch(setAlert('error', 'Unable to load your tickets.'));
    }
  }, [errorTickets, dispatch]);

  useEffect(() => {
    if (id) {
      clearTimer();
      createTimer();
    }
  }, [id, clearTimer]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  return (
    <Box
      sx={{
        background: '#F5F6FA',
        position: 'relative',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
      }}
    >
      {loadingTickets && <PageLoading />}
      <Collapse
        in={leftSlideOn}
        orientation="horizontal"
        sx={{
          minWidth: {
            xs: '100vw',
            xl: '400px',
          },
          maxWidth: {
            xs: '100vw',
            xl: '400px',
          },
          width: {
            xs: '100vw',
            xl: '400px',
          },
          '& .MuiCollapse-wrapper': {
            display: 'block',
          },
        }}
      >
        <Box
          sx={{
            '@media (min-width: 1280px)': {
              maxHeight: 'calc(100vh - 70px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '0.6em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: 2,
              },
            },
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 5,
                xl: 7,
              },
              pt: {
                xs: 2,
                sm: 3,
                lg: 5,
              },
            }}
          >
            <Box
              sx={{
                minWidth: {
                  lg: '250px',
                  xl: '300px',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <NavLink to={PATH_PERSONAL_TICKET_DASHBOARD}>
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: '500',
                  }}
                  color="text.primary"
                >
                  {'<'} Back to PTD
                </Typography>
              </NavLink>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkUnread}
                    onChange={() => {
                      setCheckUnread(!checkUnread);
                    }}
                  />
                }
                label="Unread"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  height: '100%',
                  width: '100%',
                  '& .MuiFormControlLabel-label': {
                    fontWeight: 600,
                  },
                  ml: 0,
                  mr: 0,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              py: 2,
              px: {
                xs: 2,
                sm: 3,
                md: 4,
                lg: 5,
                xl: 7,
              },
            }}
          >
            <TextField
              label="Search"
              name="search"
              value={subjectSearch}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {subjectSearch ? (
                      <IconButton onClick={() => setSubjectSearch('')}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSubjectSearch(e.target.value);
              }}
              sx={{
                marginBottom: 0,
                minWidth: {
                  lg: '250px',
                  xl: '300px',
                },
              }}
            />
          </Box>
          {dataTickets?.savvy?.personalTicketDashboard?.groups?.map((group: ITicketGroup, key: number) => {
            if (group.tickets?.length)
              return (
                <SubCollapsedTickets
                  group={group}
                  clickTicket={clickTicket}
                  key={key}
                  subjectSearch={subjectSearch}
                  checkUnread={checkUnread}
                />
              );
            else {
              return null;
            }
          })}
        </Box>
      </Collapse>
      <Hidden xlDown>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(50%, -50%)',
            '&:hover': {
              backgroundColor: 'background.default',
            },
            borderRadius: '6px',
            boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
            border: '1px solid',
            borderColor: 'grey.200',
            backgroundColor: 'background.default',
            zIndex: 999,
          }}
          onClick={() => {
            setLeftSlideOn(!leftSlideOn);
          }}
        >
          {leftSlideOn ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Hidden>
    </Box>
  );
};

export default CollapsedTickets;
