import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, TextField, MenuItem, InputAdornment, Button } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

import {
  PATH_REPORTS_BETA,
  REPORT_CART_TYPE,
  dateRangeShortCutItems,
  TOO_FEW_FLIGHTS,
  BORESCOPE_ANALYSIS_REPORT_TYPE,
  TREND_ANALYSIS_TYPE,
} from 'const';
import { aircraftRegistrationMakeModel, getGapTime } from 'helpers';
import { ButtonLoading, ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_REPORT_CARD } from 'gql';
import { IReport, IBorescopeImageSet } from 'types';
import { setAlert, storeSelectedAircraft } from 'state';
import { DebugFlightForRcTaDialog } from 'pages/SavvyAircraft/components';

interface IAircraft {
  id: string;
  aircraftManufacturer: {
    id: string;
    name: string;
  };
  aircraftModel: {
    id: string;
    name: string;
  };
  registration: string;
}

interface ReportsCardsListProps {
  aircrafts: IAircraft[];
  aircraft:
    | {
        id: string;
        reportCardEligible: boolean;
        cohortExists: boolean;
        reportCards: string;
      }
    | undefined;
  reports: IReport[];
  loading: boolean;
  setSlideNum?: (value: number) => void;
  borescopeImageSets: IBorescopeImageSet[];
  reportFilter: string;
  setReportFilter: (value: string) => void;
  cardType: string;
  setCardType: (value: string) => void;
}

const NewReportsCardList: React.FC<ReportsCardsListProps> = (props) => {
  const { aircrafts, aircraft, reports, loading, setSlideNum, borescopeImageSets, reportFilter, setReportFilter, cardType, setCardType } =
    props;

  const [createReport, { data: dataReport, loading: loadingReport, error: errorReport }] = useMutation(MUTATION_CREATE_REPORT_CARD);

  const {
    id,
    reportType: propsReportType,
    reportId,
  } = useParams<{
    id?: string;
    reportType?: string;
    reportId?: string;
  }>();

  const history = useHistory();

  const [isDebug, setIsDebug] = useState(false);

  const now = new Date();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs(`${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate()}`),
    dayjs(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`),
  ]);

  const [aircraftError, setAircraftError] = useState('');
  const [dateError, setDateError] = useState('');

  const dispatch = useDispatch();

  const createReportCard = () => {
    let valid = true;

    if (!id) {
      valid = false;
      setAircraftError('Please select aircraft');
    }

    if (!dateRange[0] || !dateRange[1]) {
      valid = false;
      setDateError('Please select start & end date');
    }

    if (!valid) return;

    createReport({
      variables: {
        aircraftId: id,
        endDate: dateRange[1]?.format('YYYY-MM-DD'),
        startDate: dateRange[0]?.format('YYYY-MM-DD'),
        kind: cardType,
      },
    });
  };

  const clickReport = async (reportCardId: string, reportType: string) => {
    if (id && reportCardId) {
      history.push(`${PATH_REPORTS_BETA}/${id}/${reportType.toLowerCase()}/${reportCardId}`);
      if (reportType === BORESCOPE_ANALYSIS_REPORT_TYPE) {
        setSlideNum && setSlideNum(2);
      }
    }
  };

  useEffect(() => {
    if (errorReport) {
      dispatch(setAlert('error', 'Unable to create new report'));
    } else {
      if (dataReport) {
        if (dataReport.createReportCard.ok) {
          dispatch(setAlert('success', 'Created new report card'));
          history.push(
            `${PATH_REPORTS_BETA}/${id}/${dataReport.createReportCard.reportCard.kind.toLowerCase()}/${
              dataReport.createReportCard.reportCard.id
            }`,
          );
        } else {
          if (dataReport.createReportCard.errorCodes?.length && dataReport.createReportCard.errorCodes.includes(TOO_FEW_FLIGHTS)) {
            setIsDebug(true);
          } else if (dataReport.createReportCard.error) {
            dispatch(setAlert('error', dataReport.createReportCard.error));
          } else {
            dispatch(setAlert('error', 'Unable to create new report'));
          }
        }
        if (setSlideNum) {
          setSlideNum(2);
        }
      }
    }
  }, [dataReport, errorReport, dispatch, setSlideNum]);

  useEffect(() => {
    if (aircrafts.length > 0 && !id) {
      setAircraftError('');
      history.push(`${PATH_REPORTS_BETA}/${aircrafts[0].id}`);
      dispatch(storeSelectedAircraft(aircrafts[0].id));
    }
  }, [aircrafts]);

  return (
    <Box
      sx={{
        backgroundColor: 'grey.800',
        borderRight: '1px solid',
        borderColor: 'grey.800',
      }}
    >
      <Box
        px={4}
        py={1.75}
        sx={{
          backgroundColor: 'grey.200',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            textTransform: 'uppercase',
            color: 'text.primary',
          }}
        >
          Aircraft
        </Typography>
      </Box>
      <Box px={4} py={3}>
        <TextField
          select
          value={id ? id : ''}
          label="Aircraft"
          variant="outlined"
          sx={{
            mb: 0,
            minWidth: '280px',
            textAlign: 'left',
          }}
          onChange={(e: any) => {
            setAircraftError('');
            history.push(`${PATH_REPORTS_BETA}/${e.target.value}`);
            dispatch(storeSelectedAircraft(e.target.value));
          }}
          error={Boolean(aircraftError)}
          helperText={aircraftError}
          fullWidth
        >
          {aircrafts?.map((item: IAircraft, key: number) => {
            return (
              <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'text.primary',
                    lineHeight: '22px',
                  }}
                >
                  {aircraftRegistrationMakeModel(item, true, 25)}
                </Typography>
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <Box
        px={4}
        py={1.75}
        sx={{
          backgroundColor: 'grey.200',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            textTransform: 'uppercase',
            color: 'text.primary',
          }}
        >
          Create Report
        </Typography>
      </Box>
      <Box px={4} py={3}>
        <Box mb={2.5}>
          <TextField
            select
            value={cardType}
            label="Report type"
            variant="outlined"
            onChange={(e: any) => {
              setCardType(e.target.value);
            }}
            sx={{
              mb: 0,
              minWidth: '280px',
            }}
            fullWidth
          >
            <MenuItem value="report_card">
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'text.primary',
                  lineHeight: '22px',
                }}
              >
                Report Card
              </Typography>
            </MenuItem>
            <MenuItem value="trend_analysis">
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'text.primary',
                  lineHeight: '22px',
                }}
              >
                Trend Analysis
              </Typography>
            </MenuItem>
          </TextField>
        </Box>
        <Box mb={2.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{
                start: 'Check-in',
                end: 'Check-out',
              }}
              value={dateRange}
              onChange={(newValue: DateRange<Dayjs>) => {
                setDateError('');
                setDateRange(newValue);
              }}
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  label: 'Choose date',
                  inputProps: {
                    readOnly: true,
                  },
                  placeholder: 'Start-End',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <DateRangeIcon />
                      </InputAdornment>
                    ),
                  },
                  error: Boolean(dateError),
                  helperText: dateError,
                  sx: {
                    minWidth: '280px',
                    mb: 0,
                  },
                  fullWidth: true,
                },
                shortcuts: {
                  items: dateRangeShortCutItems,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={createReportCard}
            sx={{}}
            disabled={!(aircraft && aircraft?.reportCardEligible) || (cardType === 'report_card' && !aircraft?.cohortExists)}
          >
            {loadingReport ? <ButtonLoading /> : 'Display'}
          </Button>
        </Box>
      </Box>
      <Box
        px={4}
        py={1.75}
        sx={{
          backgroundColor: 'grey.200',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            textTransform: 'uppercase',
            color: 'text.primary',
          }}
        >
          Previous Reports
        </Typography>
      </Box>
      <Box pt={3} pb={1.5} px={4}>
        <TextField
          select
          name="report_type"
          label="Report Type"
          value={reportFilter}
          onChange={(e) => {
            setReportFilter(e.target.value);
          }}
          sx={{
            mb: 0,
          }}
          fullWidth
        >
          <MenuItem value={REPORT_CART_TYPE}>Report Card</MenuItem>
          <MenuItem value={TREND_ANALYSIS_TYPE}>Trend Analysis</MenuItem>
          <MenuItem value={BORESCOPE_ANALYSIS_REPORT_TYPE}>Borescope Analysis</MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
        </TextField>
      </Box>
      <Box
        sx={{
          maxHeight: '700px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.6em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2,
          },
        }}
      >
        <ComponentLoading loading={loading}>
          {[...reports, ...borescopeImageSets]
            .sort((a: any, b: any) => ((a.updated || a.created) > (b.updated || b.created) ? -1 : 1))
            .map((report: any, key: number) => {
              if (reportFilter !== 'all' && report.kind && report.kind !== reportFilter) {
                return null;
              }
              if (reportFilter !== 'all' && !report.kind && reportFilter !== BORESCOPE_ANALYSIS_REPORT_TYPE) {
                return null;
              }
              return (
                <Box
                  key={key}
                  py={1.5}
                  px={4}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      ((report.kind && propsReportType === report.kind.toLowerCase()) ||
                        (!report.kind && propsReportType === BORESCOPE_ANALYSIS_REPORT_TYPE.toLowerCase())) &&
                      reportId === report.id
                        ? 'background.default'
                        : '',
                  }}
                  onClick={() => {
                    if (report.kind) {
                      clickReport(report.id, report.kind);
                    } else {
                      clickReport(report.id, BORESCOPE_ANALYSIS_REPORT_TYPE);
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '17px',
                      color: 'grey.400',
                      textTransform: 'uppercase',
                    }}
                  >
                    {report.kind ? (report.kind === REPORT_CART_TYPE ? 'Report Card' : 'Trend Analysis') : 'Borescope Analysis'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      color: 'text.primary',
                    }}
                  >
                    {getGapTime(report.kind ? report.created : report.updated)}
                  </Typography>
                  {!!report.kind && (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        lineHeight: '17px',
                        color: 'grey.400',
                      }}
                    >
                      {report.flightCount} flights between {report.dateStart.toString().substring(0, 10)} and{' '}
                      {report.dateEnd.toString().substring(0, 10)}
                    </Typography>
                  )}
                  {!report.kind && (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        lineHeight: '17px',
                        color: 'grey.400',
                      }}
                    >
                      {report.name} created at {dayjs(report.kind ? report.created : report.created).format('YYYY-MM-DD')}
                    </Typography>
                  )}
                </Box>
              );
            })}
        </ComponentLoading>
      </Box>
      <DebugFlightForRcTaDialog open={isDebug} setOpen={setIsDebug} aircraftId={id || ''} dateRange={dateRange} />
    </Box>
  );
};

export default NewReportsCardList;
