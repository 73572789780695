import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Box, Typography, Link, Button, TextField, InputAdornment } from '@mui/material';

import {
  PATH_WELCOME,
  ACCEPT_AGREEMENT_ERROR_MESSAGE,
  UPGRADE_PLAN,
  NEW_PLAN,
  CHANGE_PLAN,
  RENEW_PLAN,
  PAYMENT_UPDATE_MESSAGE,
  PATH_BETA_PLANS,
} from 'const';
import { Checkbox, ComponentLoading, ButtonLoading } from 'components';

import { MUTATION_UPGRADE_AIRCRAFT_SUBSCRIPTION, MUTATION_RENEW_SUBSCRIPTION, MUTATION_CHANGE_CREDIT_CARD } from 'gql';
import { useMutation } from '@apollo/client';

import { ChangeCcForm } from './PlanComponents';
import { useCheckbox } from 'hooks';

import { setAlert } from 'state';
import { IParamsService, IUpgradeCard, ISubscription } from 'types';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { apolloClient } from 'services';

interface UpgradePlanActionProps {
  aircraft: {
    id: string;
    registration: string;
    aircraftModel: {
      id: string;
      eligibleServices: {
        serviceCode: string;
        price: number;
      }[];
      propulsion?: string;
      enginesCount?: string;
    };
    upgradeContext: {
      options: IUpgradeCard[];
      lessExpensiveOptions: IUpgradeCard[];
    };
    subscriptions: ISubscription[];
  };
  creditCard: {
    cardType: string;
    last4: string;
    expirationMonth: number;
    expirationYear: number;
  };
}

const UpgradePlanAction: React.FC<UpgradePlanActionProps> = (props) => {
  const { aircraft, creditCard } = props;
  const { service } = useParams<IParamsService>();
  const { id, type } = useParams<{
    id: string;
    type: string;
  }>();
  const location = useLocation();

  const [showDifferentCard, setShowDifferentCard] = useState(false);

  const [upgradeSubscription, { data, loading, error }] = useMutation(MUTATION_UPGRADE_AIRCRAFT_SUBSCRIPTION, {
    errorPolicy: 'all',
  });
  const [renewSubscription, { data: dataRenewSubscription, loading: loadingRenewSubscription, error: errorRenewSubscription }] =
    useMutation(MUTATION_RENEW_SUBSCRIPTION);

  const history = useHistory();
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState('');

  // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  const stripeLoading = !stripe || !elements;
  const [loadingToken, setLoadingToken] = useState(false);

  const { value: valueAgreeEdas, error: errorAgreeEdas, setError: setErrorAgreeEdas, onChange: onChangeAgreeEdas } = useCheckbox();

  const findPrice = () => {
    const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );

    if (matchedUpgradeService?.length) {
      return `$${matchedUpgradeService[0].price}` + (service === 'prebuy' ? '' : '/year');
    }

    const matchLessService = aircraft?.upgradeContext?.lessExpensiveOptions?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );
    if (matchLessService?.length) {
      return `$${matchLessService[0].price}` + (service === 'prebuy' ? '' : '/year');
    }
    return '';
  };

  const findPriceCredit = () => {
    const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );

    if (matchedUpgradeService?.length) {
      return `$${matchedUpgradeService[0].credit}`;
    }

    const matchLessService = aircraft?.upgradeContext?.lessExpensiveOptions?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );
    if (matchLessService?.length) {
      return `$${matchLessService[0].credit}`;
    }
    return '';
  };

  const findPriceDiscount = () => {
    const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );

    if (matchedUpgradeService?.length) {
      if (matchedUpgradeService[0].discount) {
        return `$${matchedUpgradeService[0].discount}`;
      } else {
        return '';
      }
    }

    const matchLessService = aircraft?.upgradeContext?.lessExpensiveOptions?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );
    if (matchLessService?.length) {
      if (matchLessService[0].discount) {
        return `$${matchLessService[0].discount}`;
      } else {
        return '';
      }
    }
    return '';
  };

  const findPriceTotal = () => {
    const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );

    if (matchedUpgradeService?.length) {
      return `$${matchedUpgradeService[0].total}`;
    }

    const matchLessService = aircraft?.upgradeContext?.lessExpensiveOptions?.filter(
      (option: IUpgradeCard) => option.actionLink === location.pathname,
    );
    if (matchLessService?.length) {
      return `$${matchLessService[0].total}`;
    }
    return '';
  };

  const getSubscriptionId = () => {
    const matchedContext = aircraft?.upgradeContext?.options?.find((option: IUpgradeCard) => option.actionLink === location.pathname);
    if (matchedContext) {
      return matchedContext.subscriptionId;
    }
    return '';
  };

  const onSubmit = async () => {
    if (!valueAgreeEdas) {
      setErrorAgreeEdas(ACCEPT_AGREEMENT_ERROR_MESSAGE);
      return;
    }

    if (showDifferentCard) {
      if (stripe === null || elements === null) return;

      const cardElement = elements.getElement(CardElement);

      // Prevent Typescript errors if Stripe still loading
      if (cardElement === null) {
        return;
      }

      setLoadingToken(true);

      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        const { message } = error;
        setLoadingToken(false);

        if (message) {
          setStripeError(message);
          return;
        }
      } else if (token) {
        const { data: dataUpdateToken, errors: errorUpdateToken } = await apolloClient.mutate({
          mutation: MUTATION_CHANGE_CREDIT_CARD,
          variables: {
            token: token.id,
          },
          errorPolicy: 'all',
        });

        setLoadingToken(false);

        if (errorUpdateToken) {
          setStripeError(errorUpdateToken[0].message);
          dispatch(setAlert('error', errorUpdateToken[0].message));
          return;
        } else if (dataUpdateToken) {
          const { changeCreditCardToken } = dataUpdateToken;

          setLoadingToken(false);
          if (changeCreditCardToken.ok) {
            dispatch(setAlert('success', PAYMENT_UPDATE_MESSAGE));
            setShowDifferentCard(false);
          } else {
            dispatch(setAlert('error', changeCreditCardToken.error));
          }
        }
      }
    }

    if (type === UPGRADE_PLAN || type === CHANGE_PLAN) {
      await upgradeSubscription({
        variables: {
          aircraftId: aircraft.id,
          serviceCode: service,
        },
      });
    } else if (type === RENEW_PLAN) {
      await renewSubscription({
        variables: {
          subscriptionId: getSubscriptionId(),
          serviceCode: service,
        },
      });
    }
  };

  useEffect(() => {
    if (type && service) {
      const matchedUpgradeService = aircraft?.upgradeContext?.options?.filter(
        (option: IUpgradeCard) => option.actionLink === location.pathname,
      );

      if (matchedUpgradeService?.length) {
        return;
      }

      const matchLessService = aircraft?.upgradeContext?.lessExpensiveOptions?.filter(
        (option: IUpgradeCard) => option.actionLink === location.pathname,
      );

      if (matchLessService?.length) {
        return;
      }

      if (type === RENEW_PLAN) {
        history.push(`${PATH_BETA_PLANS}/${id}/${NEW_PLAN}/${service}`);
      }
    }
  }, [type, service]);

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors) {
        dispatch(setAlert('error', error.graphQLErrors[0].message));
      } else {
        dispatch(setAlert('error', 'Unable to upgrade new plan'));
      }
    } else if (data) {
      if (data?.upgradeAircraftSubscription?.ok) {
        dispatch(setAlert('success', 'Upgraded your plan'));
        history.push(
          `${PATH_WELCOME}/${data.upgradeAircraftSubscription.aircraft.id}/${service}?category=${aircraft.aircraftModel?.propulsion?.toLowerCase()}-${aircraft.aircraftModel?.enginesCount?.toLowerCase()}`,
        );
      } else {
        dispatch(setAlert('error', data?.upgradeAircraftSubscription?.error || 'Unable to upgrade your plan'));
      }
    }
  }, [error, data, dispatch, history, service]);

  useEffect(() => {
    if (errorRenewSubscription) {
      dispatch(setAlert('error', 'Unable to renew your plan'));
    } else if (dataRenewSubscription) {
      if (dataRenewSubscription?.renewSubscription?.ok) {
        dispatch(setAlert('success', 'Renew your plan'));
        history.push(
          `${PATH_WELCOME}/${dataRenewSubscription?.renewSubscription.aircraft.id}/${service}?category=${aircraft.aircraftModel?.propulsion?.toLowerCase()}-${aircraft.aircraftModel?.enginesCount?.toLowerCase()}`,
        );
      } else {
        dispatch(setAlert('error', dataRenewSubscription?.renewSubscription?.error || 'Unable to renew your plan'));
      }
    }
  }, [errorRenewSubscription, dataRenewSubscription, dispatch, history, service]);

  return (
    <Box>
      <ComponentLoading loading={loading || loadingRenewSubscription}>
        <Box mb={1}>
          <Box
            sx={{
              py: 1,
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: 'grey.200',
            }}
          >
            <Box
              py={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>{service === 'prebuy' ? 'Fixed fee for your plan:' : 'Fixed annual fee for your plan:'}</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {findPrice()}
              </Typography>
            </Box>
            <Box
              py={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Credit:</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {findPriceCredit()}
              </Typography>
            </Box>
            {!!findPriceDiscount() && (
              <Box
                py={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Discount:</Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {findPriceDiscount()}
                </Typography>
              </Box>
            )}
            <Box
              py={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Payment due today:</Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {findPriceTotal()}
              </Typography>
            </Box>
          </Box>
        </Box>
        {type !== RENEW_PLAN && (
          <Box py={1}>
            {!showDifferentCard && (
              <Box>
                <TextField
                  label={`${creditCard?.cardType || ''} Card Number`}
                  value={`xxxx-xxxx-xxxx-${creditCard?.last4 || ''}`}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{`${('0' + (creditCard?.expirationMonth || '')).slice(
                        -2,
                      )}/${creditCard?.expirationYear}`}</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Box>
            )}
            {!showDifferentCard && (
              <Typography
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  setShowDifferentCard(true);
                }}
              >
                Use a different card?
              </Typography>
            )}
          </Box>
        )}
        {showDifferentCard && (
          <Box py={1}>
            <ChangeCcForm setShowDifferentCard={setShowDifferentCard} errorMessage={stripeError} />
          </Box>
        )}
        <Box py={1}>
          <Checkbox
            value={valueAgreeEdas}
            error={errorAgreeEdas}
            label={
              <Typography>
                I accept the terms and conditions of the{' '}
                <Link
                  href="https://www.savvyaviation.com/master-service-agreement"
                  download="savvy-aviation-master-service-agreement.pdf"
                  target="_blank"
                >
                  SavvyAviation Master Services Agreement
                </Link>
                . A copy of the agreement will be emailed to you.
              </Typography>
            }
            onChange={(e) => onChangeAgreeEdas(e)}
            checked={valueAgreeEdas}
          />
        </Box>
        <Box
          py={1}
          sx={{
            textAlign: 'center',
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            {loadingToken || stripeLoading ? <ButtonLoading /> : 'Pay and Continue'}
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default UpgradePlanAction;
