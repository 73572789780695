import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Typography, Link } from '@mui/material';

import { QUERY_DEBUG_FLIGHT_COUNT_FOR_RCTA } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';

import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

interface DebugFlightForRcTaDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  dateRange: DateRange<Dayjs>;
}

const DebugFlightForRcTaDialog: React.FC<DebugFlightForRcTaDialogProps> = (props) => {
  const { open, setOpen, aircraftId, dateRange } = props;
  const [fetchDebugRcTa, { data, error, loading }] = useLazyQuery(QUERY_DEBUG_FLIGHT_COUNT_FOR_RCTA);
  const dispatch = useDispatch();

  const [flightsCount, setFlightsCount] = useState(0);
  const [flights, setFlights] = useState<
    {
      id: string;
      rcStatus: string;
    }[]
  >([]);

  const [showDetail, setShowDetail] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to debug failed RC or TA'));
      setOpen(false);
    } else if (data) {
      if (data.debugFlightCountForRcTa) {
        setFlightsCount(data.debugFlightCountForRcTa.flightCount);
        setFlights([...data.debugFlightCountForRcTa.flights]);
      }
    }
  }, [error, data, dispatch, setOpen]);

  useEffect(() => {
    if (open) {
      setShowDetail(false);
      fetchDebugRcTa({
        variables: {
          aircraftId,
          startDate: dateRange[0]?.format('YYYY-MM-DD'),
          endDate: dateRange[1]?.format('YYYY-MM-DD'),
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [open, aircraftId, dateRange]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '768px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
          color: 'error.main',
        }}
      >
        Failed to create report card
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading}>
          <Box py={1}>
            {!!flightsCount && (
              <Typography>
                Too few flights for report during specified time period.
                <br />
                {!showDetail && (
                  <Link
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDetail(true);
                    }}
                  >
                    Show details...
                  </Link>
                )}
              </Typography>
            )}
            {!flightsCount && <Typography>There were no flights found for this aircraft. Upload some flights and try again.</Typography>}
          </Box>
          {!!flights.length && showDetail && (
            <Box>
              <Box py={1}>Number of flights: {flightsCount}</Box>
              {flights.map((item, key: number) => {
                return (
                  <Box py={1} key={key}>
                    <Typography>
                      Flight #{item.id}: {item.rcStatus}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Box
          py={1}
          sx={{
            textAlign: 'center',
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DebugFlightForRcTaDialog;
