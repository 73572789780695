import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, ToggleButtonGroup, ToggleButton, Grid, TextField, MenuItem } from '@mui/material';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { MuiFormikInput, MuiFormikInputForBorescopeSubject, ButtonSubmit, TicketTextareaWithFile, ComponentLoading } from 'components';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_CREATE_BORESCOPE_ANALYSIS_TICKET, QUERY_BORESCOPE_IMAGE_SETS } from 'gql';
import { setAlert } from 'state';
import { IAircraftEligibility, ITicketEligibility, IUploadedFile, IBorescopeImageSet } from 'types';
import {
  PATH_TICKETS_VIEW,
  MAX_SUBJECT_LENGTH,
  SHOW_SUBJECT_WARNING,
  BORESCOPE_ANALYSIS_TICKET_URL_QUERY,
  PATH_BORESCOPE_UPLOAD,
} from 'const';
import { usePrevious } from 'hooks';
import { ctrlCmdClick } from 'helpers';

const PostTicketSchema = Yup.object().shape({
  subject: Yup.string().max(MAX_SUBJECT_LENGTH, `Too long: 1-${MAX_SUBJECT_LENGTH} characters required`).required('Required'),
  body: Yup.string().max(10000, 'Too long: 1-10000 characters required').required('Required'),
  priorityExplanation: Yup.string().max(255, 'Too long: 1-255 characters required'),
});

interface submitValueProps {
  subject: string;
  body: string;
  priorityExplanation: string;
}

interface BorescopeAnalysisFormComponentProps {
  ticketAircraft: IAircraftEligibility;
  eligibility: ITicketEligibility;
  borescopeImageSet?: IBorescopeImageSet;
}

const BorescopeAnalysisFormComponent: React.FC<BorescopeAnalysisFormComponentProps> = (props) => {
  const { id } = useParams<{
    id: string;
  }>();
  const { ticketAircraft, eligibility, borescopeImageSet } = props;

  const [priority, setPriority] = useState('normal');
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const [createTicket, { data: dataCreateTicket, error: errorCreateTicket, loading: loadingCreateTicket }] = useMutation(
    MUTATION_CREATE_BORESCOPE_ANALYSIS_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const [fetchBorescopeImageSets, { data: dataSets, error: errorSets, loading: loadingSets }] = useLazyQuery(QUERY_BORESCOPE_IMAGE_SETS);

  const [borescopeImageSetId, setBorescopeImageSetId] = useState('');
  const [borescopeError, setBorescopeError] = useState('');

  const previousSetId = usePrevious(borescopeImageSetId);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<IUploadedFile[]>([]);

  const onSubmit = async (value: submitValueProps) => {
    if (!borescopeImageSetId) {
      setBorescopeError('Select Borescope Image Set');
      return;
    }

    const documentIds = uploadedFileList.map((file: IUploadedFile) => {
      return file.id;
    });

    if (priority === 'high') {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          aircraftId: ticketAircraft.id,
          borescopeImageSetId,
          priority: 'U',
          priorityExplanation: value.priorityExplanation,
          documentIds: documentIds ? documentIds : [],
        },
      });
    } else {
      await createTicket({
        variables: {
          body: value.body,
          subject: value.subject,
          aircraftId: ticketAircraft.id,
          borescopeImageSetId,
          priority: 'N',
          documentIds: documentIds ? documentIds : [],
        },
      });
    }
  };

  const getSubjectName = (id: string) => {
    if (!id) {
      return '';
    }
    const matched = dataSets?.me?.aircraft[0]?.borescopeImageSets?.find((item: IBorescopeImageSet) => item.id === id);
    if (matched) {
      return `Borescope Analysis ${ticketAircraft.registration}: ${matched.name}`;
    }
    return '';
  };

  useEffect(() => {
    if (ticketAircraft) {
      fetchBorescopeImageSets({
        variables: {
          aircraftId: parseInt(ticketAircraft.id),
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [ticketAircraft, fetchBorescopeImageSets]);

  useEffect(() => {
    if (errorSets) {
      dispatch(setAlert('error', 'Unable to load borescopeImageSets'));
    }
  }, [errorSets, dispatch]);

  useEffect(() => {
    if (errorCreateTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataCreateTicket) {
      if (dataCreateTicket.createBorescopeAnalysisTicket?.ok) {
        dispatch(setAlert('success', 'Created new ticket'));
        history.push(`${PATH_TICKETS_VIEW}/${dataCreateTicket.createBorescopeAnalysisTicket.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataCreateTicket.createBorescopeAnalysisTicket?.error || 'Unable to create ticket'));
      }
    }
  }, [errorCreateTicket, dispatch, dataCreateTicket, history]);

  useEffect(() => {
    if (search) {
      const str = new URLSearchParams(search);
      const setId = str.get(BORESCOPE_ANALYSIS_TICKET_URL_QUERY);

      if (setId) {
        setBorescopeImageSetId(setId);
      }
    }
  }, [search]);

  useEffect(() => {
    if (borescopeImageSet) {
      setBorescopeImageSetId(borescopeImageSet.id);
    }
  }, [borescopeImageSet]);

  if (!eligibility.free && !(eligibility.eligible && eligibility.currentlyEligibleVia.length)) return null;

  return (
    <ComponentLoading loading={loadingCreateTicket || loadingSets}>
      <Box py={3}>
        {!!dataSets?.me?.aircraft[0]?.borescopeImageSets?.length && (
          <Box py={1}>
            <TextField
              select
              value={borescopeImageSetId}
              label="Choose the Borescope Image Set"
              variant="outlined"
              sx={{
                minWidth: '300px',
                textAlign: 'left',
              }}
              name="borescopeImageId"
              onChange={(e: any) => {
                setBorescopeImageSetId(e.target.value);
                setBorescopeError('');
              }}
              fullWidth
              error={Boolean(borescopeError)}
              helperText={borescopeError ? borescopeError : ''}
              FormHelperTextProps={{
                style: {
                  marginLeft: 0,
                },
              }}
              disabled={!!borescopeImageSet}
              inputProps={{
                'data-testid': 'borescope-image-selector',
              }}
            >
              {Boolean(dataSets?.me?.aircraft[0]?.borescopeImageSets?.length) &&
                dataSets?.me?.aircraft[0]?.borescopeImageSets.map((item: IBorescopeImageSet, key: number) => {
                  if (item.status === 'REPORT_SENT') {
                    return null;
                  }
                  return (
                    <MenuItem value={item.id} key={key} disabled={item.images.length === 0 || item.status === 'ANALYSIS_REQUESTED'}>
                      {item.name}
                      {item.images.length === 0
                        ? ' : [Set contains no images]'
                        : item.status === 'ANALYSIS_REQUESTED'
                          ? ' : [ticket already exists]'
                          : ''}
                    </MenuItem>
                  );
                })}
              {dataSets?.me?.aircraft[0]?.borescopeImageSets.filter(
                (item: IBorescopeImageSet) => item.status === 'IN_PROGRESS' && item.images.length !== 0,
              ).length === 0 && (
                <MenuItem>
                  <Link to={`${PATH_BORESCOPE_UPLOAD}/${id}`}>Upload a new set</Link>
                </MenuItem>
              )}
            </TextField>
          </Box>
        )}
        {!dataSets?.me?.aircraft[0]?.borescopeImageSets?.length && (
          <Box py={1}>
            <Typography align="center">
              You have no uploaded borescope images.{' '}
              <Link
                to={`${PATH_BORESCOPE_UPLOAD}/${ticketAircraft.id}`}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ctrlCmdClick(e, `${PATH_BORESCOPE_UPLOAD}/${ticketAircraft.id}`, history);
                }}
              >
                Upload a new set...
              </Link>
            </Typography>
          </Box>
        )}
        {!!dataSets?.me?.aircraft[0]?.borescopeImageSets?.length && (
          <Formik
            initialValues={{
              subject: '',
              body: '',
              priorityExplanation: '',
            }}
            validationSchema={PostTicketSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, handleChange, handleBlur, touched, errors, setFieldValue, values }) => {
              return (
                <Form>
                  <Box py={1}>
                    <MuiFormikInputForBorescopeSubject
                      name="subject"
                      label="Subject"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={MAX_SUBJECT_LENGTH}
                      closeLength={SHOW_SUBJECT_WARNING}
                      setFieldValue={setFieldValue}
                      previousSubject={getSubjectName(previousSetId)}
                      subject={getSubjectName(borescopeImageSetId)}
                      value={values.subject}
                    />
                  </Box>
                  <Box py={1} pb={2.5}>
                    <TicketTextareaWithFile
                      name="body"
                      label="Message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      rows={5}
                      maxLength={10000}
                      maxLine={10}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                      uploadedFileList={uploadedFileList}
                      setUploadedFileList={setUploadedFileList}
                    />
                  </Box>
                  <Box pt={1} pb={2.5}>
                    <Typography
                      sx={{
                        color: 'grey.600',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                      }}
                      gutterBottom
                    >
                      Priority
                    </Typography>
                    <ToggleButtonGroup
                      size="medium"
                      value={priority}
                      onChange={(event, priority) => {
                        setPriority(priority);
                      }}
                      exclusive={true}
                    >
                      <ToggleButton
                        value={'normal'}
                        key={'normal'}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'warning.main',
                            color: 'background.default',
                            '&:hover': {
                              backgroundColor: 'warning.main',
                            },
                          },
                          width: '80px',
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          fontWeight: 700,
                          py: 1,
                        }}
                      >
                        Normal
                      </ToggleButton>
                      <ToggleButton
                        value={'high'}
                        key={'high'}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'error.dark',
                            color: 'background.default',
                            '&:hover': {
                              backgroundColor: 'error.dark',
                            },
                          },
                          width: '80px',
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          fontWeight: 700,
                          py: 1,
                        }}
                      >
                        High
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  {priority === 'high' && (
                    <Box mb={3}>
                      <Box py={1}>
                        <Typography
                          color="error"
                          variant="h3"
                          textAlign="center"
                          sx={{
                            mb: 1,
                          }}
                        >
                          Please Don't Abuse Urgent Priority
                        </Typography>
                        <Typography variant="body1" textAlign="center" gutterBottom>
                          We will do our best to do this analysis as soon as possible, but&nbsp;
                          <b>we cannot guarantee a faster turnaround</b>.
                        </Typography>
                      </Box>
                      <MuiFormikInput
                        name="priorityExplanation"
                        label="Priority explanation"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        noMargin
                      />
                    </Box>
                  )}
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={false}></Grid>
                    <Grid item md={6} xs={12}>
                      <ButtonSubmit
                        text="Create Ticket"
                        loading={isSubmitting || loadingCreateTicket}
                        disabled={isSubmitting || loadingCreateTicket || isUploading}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
      </Box>
    </ComponentLoading>
  );
};

export default BorescopeAnalysisFormComponent;
