import React, { Fragment, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

import { useDispatch } from 'react-redux';
import { setAlert, removeAlert } from 'state';
import { ACCOUNT_EXIST_ERROR } from 'state/types';

import { useLazyQuery } from '@apollo/client';
import { QUERY_ACCOUNT_EXIST } from 'gql';

import { handleInputServerErrors } from 'helpers';

import { IInputSetError } from 'types';

import { PATH_LOGIN } from 'const';

// Constant to have the same string and prevent typing error
const ALERT_EMAIL_IN_USE_ID = 'ALERT_EMAIL_IN_USE_ID';

// Error Message UI
interface IEmailInUseError {
  linkColor?: 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}
const EmailInUseError: React.FC<IEmailInUseError> = (props) => {
  const { linkColor } = props;
  return (
    <Fragment>
      This email is already taken, please{' '}
      <RouterLink to={PATH_LOGIN}>
        <Box color="white" component="span">
          <Link color={linkColor && linkColor} underline="always" component="span">
            login
          </Link>
        </Box>
      </RouterLink>
    </Fragment>
  );
};
// Hook (logic)
interface IUseEmailInUse {
  email: string;
  setErrorEmail: IInputSetError;
}
const useEmailInUse = (props: IUseEmailInUse) => {
  const { email, setErrorEmail } = props;
  const dispatch = useDispatch();

  // Account Exist (email in use, already registered)
  // GraphQL Definition
  const [refetchAccountExist, { loading: loadingAccountExist, data: dataAccountExist, error: errorFetchAccountExist }] = useLazyQuery(
    QUERY_ACCOUNT_EXIST,
    { variables: { email } },
  );

  // Check Account Exist when refetched
  useEffect(() => {
    // Call GraphQL. Refetch
    refetchAccountExist();

    // Response
    if (!loadingAccountExist && dataAccountExist) {
      const { errors } = dataAccountExist.accounts;
      // Errors
      if (errors) {
        // Email Server Errors
        handleInputServerErrors(errors, 'email', setErrorEmail);
      } else {
        // Success
        const { exists } = dataAccountExist.accounts;

        // Account Exist
        if (exists) {
          setErrorEmail(<EmailInUseError />);
          dispatch(setAlert('error', <EmailInUseError linkColor="inherit" />, ALERT_EMAIL_IN_USE_ID)); // This is serious error. Let's show alert
          dispatch({ type: ACCOUNT_EXIST_ERROR });
        }
      }
    }
  }, [dataAccountExist, errorFetchAccountExist, dispatch, loadingAccountExist, refetchAccountExist, setErrorEmail]); // Watch Error Also. And Refetch

  // Remove alert on email change
  const removeAlertEmailInUse = () => {
    dispatch(removeAlert(ALERT_EMAIL_IN_USE_ID));
  };

  return { removeAlertEmailInUse };
};

export default useEmailInUse;
