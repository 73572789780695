import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, IconButton, Typography, Link } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MapGL, { MapRef, Source, Layer, NavigationControl, FullscreenControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import mapboxgl from 'mapbox-gl';

import { ComponentLoading } from 'components';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FLIGHT_CHART_DETAIL_BY_ID, QUERY_SHARED_FLIGHT_CHART_DETAIL_BY_ID, QUERY_KML_DATA } from 'gql';

import { getAngleLineOnMap, makeKmlString, downloadKmlFile } from 'helpers';
import { IFlightData } from 'types';
import { PATH_LEGACY_FLIGHT_VIEW, NOTIFICATION_BAR_ID } from 'const';
import { setAlert } from 'state';

interface FlightMapTabProps {
  flightData: IFlightData;
}

const MAP_CONTAINER_ID = 'map-container';

const FlightMapTab: React.FC<FlightMapTabProps> = (props) => {
  const { flightData } = props;

  const { hoverPointIndex: hoverChartPoint } = useSelector((state: any) => state.mapReducer);
  const { loggedIn } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const [initialLoaded, setInitialLoaded] = useState(false);

  const [fetchMap, { data: dataFlightData, loading }] = useLazyQuery(
    loggedIn ? QUERY_FLIGHT_CHART_DETAIL_BY_ID : QUERY_SHARED_FLIGHT_CHART_DETAIL_BY_ID,
  );

  const [fetchKml, { data: dataKml, loading: loadingKml }] = useLazyQuery(QUERY_KML_DATA);

  const mapData = loggedIn
    ? dataFlightData?.me?.flight[0]
      ? JSON.parse(dataFlightData.me?.flight[0].data)
      : undefined
    : dataFlightData?.sharedFlight
      ? JSON.parse(dataFlightData.sharedFlight?.data)
      : undefined;

  const [viewPort, setViewPort] = useState({
    longitude: -94,
    latitude: 39.88,
    zoom: 3,
  });

  const mapRef = useRef<MapRef | null>(null);

  const [flightAngle, setFlightAngle] = useState(0);

  const getRouterStart = (lats: number[], lons: number[]) => {
    const maxLength = Math.min(lats.length, lons.length);

    for (let i = 0; i < maxLength; i++) {
      if (Math.abs(lats[i] - 39.0676) < 0.2 && Math.abs(lons[i] + 94.8977) < 0.02) {
        continue;
      }
      return {
        x: lons[i],
        y: lats[i],
      };
    }

    return {
      x: 0,
      y: 0,
    };
  };

  const getRoute = (lons: number[], lats: number[], maxLength: number) => {
    const points: any[] = [];

    for (let i = 0; i < maxLength; i++) {
      if (Math.abs(lats[i] - 39.0676) < 0.02 && Math.abs(lons[i] + 94.8977) < 0.02) {
        continue;
      }
      points.push([lons[i], lats[i]]);
    }

    return points;
  };

  const downloadFile = async (flightId: string) => {
    if (dataKml?.kmlData?.points?.length) {
      downloadKmlFile(makeKmlString(dataKml?.kmlData?.flight, dataKml?.kmlData?.points || []), flightData);
      return;
    }
    await fetchKml({
      variables: {
        flightId: parseInt(flightId),
      },
    });
  };

  useEffect(() => {
    if (!flightData) return;
    if (loggedIn) {
      fetchMap({
        variables: {
          id: parseInt(flightData.id),
          serieNames: ['LAT', 'LON'],
        },
      });
    } else {
      if (flightData.id && flightData.publicUuid) {
        fetchMap({
          variables: {
            flightId: flightData.id,
            flightUuid: flightData.publicUuid,
            serieNames: ['LAT', 'LON'],
          },
        });
      } else {
        dispatch(setAlert('error', 'Unable to load flight data'));
      }
    }
  }, [flightData, loggedIn, fetchMap]);

  useEffect(() => {
    if (mapData) {
      if (
        mapData['series_data']['LAT'] &&
        mapData['series_data']['LON'] &&
        hoverChartPoint > -1 &&
        hoverChartPoint < mapData['series_data']['LAT'].length &&
        hoverChartPoint < mapData['series_data']['LON'].length
      ) {
        const length = Math.min(mapData['series_data']['LAT'].length, mapData['series_data']['LON'].length);

        const pointLat = mapData['series_data']['LAT'][hoverChartPoint];
        const pointLon = mapData['series_data']['LON'][hoverChartPoint];
        let flag = false;
        let i = 0;
        for (i = hoverChartPoint + 1; i < length; i++) {
          if (pointLat !== mapData['series_data']['LAT'][i] || pointLon !== mapData['series_data']['LON'][i]) {
            flag = true;
            break;
          }
        }

        if (flag) {
          const targetPointLat = mapData['series_data']['LAT'][i];
          const targetPointLon = mapData['series_data']['LON'][i];

          setFlightAngle(getAngleLineOnMap(pointLon, pointLat, targetPointLon, targetPointLat));
        } else {
          setFlightAngle(0);
        }
      }
    }
  }, [hoverChartPoint, mapData]);

  useEffect(() => {
    if (dataKml?.kmlData) {
      downloadKmlFile(makeKmlString(dataKml?.kmlData?.flight, dataKml?.kmlData?.points || []), flightData);
    }
  }, [dataKml]);

  useEffect(() => {
    if (!initialLoaded) {
      const element = document.getElementById(MAP_CONTAINER_ID);
      const height = element?.offsetHeight || 0;
      if (height) {
        setInitialLoaded(true);
        mapRef.current?.getMap().resize();
        FitMap();
      }
    }
  });

  if (!dataFlightData) return null;

  if (!mapData || !mapData['series_data']['LAT']?.length || !mapData['series_data']['LON']?.length) {
    return (
      <Box p={2.5}>
        <Typography
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          No GPS data is available for this flight.
        </Typography>
      </Box>
    );
  }

  const maxLength = Math.min(mapData['series_data']['LAT'].length, mapData['series_data']['LON'].length);

  const start = getRouterStart(mapData['series_data']['LAT'], mapData['series_data']['LON']);

  const margin = 0.005;

  const long1 = start.x;
  const lat1 = start.y;
  const long2 = mapData['series_data']['LON'][maxLength - 1];
  const lat2 = mapData['series_data']['LAT'][maxLength - 1];

  let north_lat = Math.max(lat1, lat2);
  let south_lat = Math.min(lat1, lat2);

  north_lat += Math.abs(north_lat) * margin;
  south_lat -= Math.abs(south_lat) * margin;

  let east_long, west_long, distance;

  // If they are on the same hemisphere, the smallest one is west.
  if ((long1 >= 0 && long2 >= 0) || (long1 <= 0 && long2 <= 0)) {
    east_long = Math.max(long1, long2);
    west_long = Math.min(long1, long2);
    distance = Math.abs(east_long - west_long);
  } else {
    // One is positive and one negative so the sum of the abs values is their distance
    distance = Math.abs(long1) + Math.abs(long2);
    // If the min bound works this way, then the negative one is the westernmost point
    if (distance < 180) {
      east_long = Math.max(long1, long2);
      west_long = Math.min(long1, long2);
    } else {
      distance = 360 - distance;
      east_long = Math.min(long1, long2);
      west_long = Math.max(long1, long2);
    }
  }

  east_long += Math.abs(distance) * margin;
  west_long -= Math.abs(distance) * margin;

  if (isNaN(west_long) || isNaN(south_lat) || isNaN(east_long) || isNaN(north_lat)) {
    return (
      <Box p={2.5}>
        <Typography
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          No GPS data is available for this flight.
        </Typography>
      </Box>
    );
  }

  let sw: mapboxgl.LngLat;
  let ne: mapboxgl.LngLat;

  try {
    sw = new mapboxgl.LngLat(west_long, south_lat);
    ne = new mapboxgl.LngLat(east_long, north_lat);
  } catch {
    return (
      <Box p={2.5}>
        <Typography
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'error.main',
          }}
        >
          Unable to load flight map
        </Typography>
      </Box>
    );
  }
  // const llb = new mapboxgl.LngLatBounds(sw, ne);

  const route = getRoute(mapData['series_data']['LON'], mapData['series_data']['LAT'], maxLength);

  const makeFlightRouter = (lons: number[], lats: number[], index: number) => {
    const tmpLength = Math.min(lons.length, lats.length, index);

    const points: any[] = [];

    for (let i = 0; i < tmpLength; i++) {
      if (Math.abs(lats[i] - 39.0676) < 0.02 && Math.abs(lons[i] + 94.8977) < 0.02) {
        continue;
      }
      points.push([lons[i], lats[i]]);
    }

    return points;
  };

  const getInitialViewPort = () => {
    const element = document.getElementById(MAP_CONTAINER_ID);
    if (isNaN(sw.lng) || isNaN(sw.lat) || isNaN(ne.lng) || isNaN(ne.lat)) {
      return new WebMercatorViewport({
        width: 400,
        height: element?.offsetHeight || 600,
      }).fitBounds(
        [
          [0, 0],
          [90, 90],
        ],
        {
          padding: 25,
        },
      );
    }
    return new WebMercatorViewport({
      width: 400,
      height: element?.offsetHeight || 600,
    }).fitBounds(
      [
        [sw.lng, sw.lat],
        [ne.lng, ne.lat],
      ],
      {
        padding: 25,
      },
    );
  };
  const FitMap = () => {
    const element = document.getElementById(MAP_CONTAINER_ID);
    if (element?.offsetWidth) {
      const tmpViewPort = new WebMercatorViewport({
        width: element.offsetWidth,
        height: element.offsetHeight,
      }).fitBounds(
        [
          [sw.lng, sw.lat],
          [ne.lng, ne.lat],
        ],
        {
          padding: 25,
        },
      );

      setViewPort(tmpViewPort);
    }
  };
  const NotificationElement = document.getElementById(NOTIFICATION_BAR_ID);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <ComponentLoading loading={loading}>
        <Box
          sx={{
            width: '100%',
            height: `calc(100vh - ${200 + (NotificationElement?.offsetHeight || 0)}px)`,
            position: 'relative',
          }}
          id={MAP_CONTAINER_ID}
        >
          <MapGL
            {...viewPort}
            onMove={(evt) => setViewPort(evt.viewState)}
            mapStyle="mapbox://styles/thanossavvy/cit0gsho4001c2wpidkw2k667"
            mapboxAccessToken={process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN ? process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN : ''}
            ref={mapRef}
            onLoad={() => {
              setViewPort(getInitialViewPort());
              const map = mapRef?.current?.getMap();
              if (!map) return;
              map.loadImage('/images/flag-16.png', (error: any, image: any) => {
                if (error) return;
                if (image && !map.hasImage('flag')) {
                  map.addImage('flag', image, {
                    sdf: true,
                  });
                }
              });
            }}
          >
            <FullscreenControl
              style={{
                left: 10,
                top: 10,
              }}
              position="top-left"
            />
            <NavigationControl
              style={{
                bottom: 40,
                right: 10,
              }}
              position="bottom-right"
              showCompass={false}
            />
            <Source
              type="geojson"
              data={{
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [long1, lat1],
                },
                properties: {
                  title: flightData.departureAirport,
                },
              }}
            >
              <Layer
                id="points-circles"
                type="circle"
                paint={{
                  'circle-radius': 15,
                  'circle-color': '#3F75FF',
                  'circle-opacity': 0.2,
                }}
              />
              <Layer
                id="points-icons"
                type="symbol"
                layout={{
                  'icon-image': 'flag',
                  'text-field': '{title}',
                  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                  'text-offset': [0, 0.8],
                  'text-anchor': 'top',
                  'text-size': 14,
                }}
                paint={{
                  'icon-color': 'green',
                }}
              />
            </Source>
            <Source
              type="geojson"
              data={{
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [long2, lat2],
                },
                properties: {
                  title: flightData.destinationAirport,
                },
              }}
            >
              <Layer
                id="points-circles-1"
                type="circle"
                paint={{
                  'circle-radius': 15,
                  'circle-color': '#3F75FF',
                  'circle-opacity': 0.2,
                }}
              />
              <Layer
                id="points-icons-1"
                type="symbol"
                layout={{
                  'icon-image': 'flag',
                  'text-field': '{title}',
                  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                  'text-offset': [0, 0.8],
                  'text-anchor': 'top',
                  'text-size': 14,
                }}
                paint={{
                  'icon-color': 'red',
                }}
              />
            </Source>
            <Source
              type="geojson"
              data={{
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: route,
                },
              }}
            >
              <Layer
                id="route"
                type="line"
                layout={{
                  'line-join': 'round',
                  'line-cap': 'round',
                }}
                paint={{
                  'line-color': 'purple',
                  'line-width': 4,
                }}
              />
            </Source>
            {hoverChartPoint > 0 && (
              <Source
                type="geojson"
                data={{
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: makeFlightRouter(mapData['series_data']['LON'], mapData['series_data']['LAT'], hoverChartPoint),
                  },
                }}
              >
                <Layer
                  id="flight-route"
                  type="line"
                  layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                  }}
                  paint={{
                    'line-color': 'rgba(63, 117, 255, 1)',
                    'line-width': 4,
                  }}
                />
              </Source>
            )}
            {hoverChartPoint > 0 &&
              hoverChartPoint < mapData['series_data']['LON'].length &&
              hoverChartPoint < mapData['series_data']['LAT'].length && (
                <Source
                  type="geojson"
                  data={{
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [mapData['series_data']['LON'][hoverChartPoint], mapData['series_data']['LAT'][hoverChartPoint]],
                    },
                    properties: {
                      title: 'flight',
                    },
                  }}
                >
                  <Layer
                    id="flight-point"
                    type="symbol"
                    layout={{
                      'icon-image': 'airfield-15',
                      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                      'text-offset': [0, 0.8],
                      'text-anchor': 'top',
                      'text-size': 14,
                      'icon-rotate': flightAngle,
                    }}
                    paint={{}}
                  />
                </Source>
              )}
          </MapGL>
          <IconButton
            sx={{
              borderRadius: '4px',
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'background.default',
              padding: '2.5px',
              boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.5)',
              },
            }}
            onClick={FitMap}
          >
            <FullscreenIcon />
          </IconButton>
        </Box>
        <ComponentLoading loading={loadingKml}>
          <Box
            py={1}
            sx={{
              textAlign: 'right',
            }}
          >
            <Link
              href={`${PATH_LEGACY_FLIGHT_VIEW}/${flightData.id}/${flightData.publicUuid}/kml`}
              target="_blank"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                downloadFile(flightData.id);
              }}
            >
              Download KML
            </Link>
          </Box>
        </ComponentLoading>
      </ComponentLoading>
    </Box>
  );
};

export default FlightMapTab;
